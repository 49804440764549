import React, { useState, useEffect } from 'react';
import { useAccount, WagmiConfig, createClient } from 'wagmi';
import { ConnectButton, RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';
import {  chain, configureChains } from 'wagmi';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import "@rainbow-me/rainbowkit/styles.css";
import './App.css';
import { ethers } from 'ethers';
import ABI from './ABI.json'
import USDCABI from './USDCABI.json'
import mintPng from './mintPng.png'
import logo from './logo.png'
import logoMutated from './mutated.png'
import pinball from './pinball.png'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

const customNetwork = {
  id: 7171,
  name: 'BitRock',
  network: 'BitRock',
  nativeCurrency: {
    name: 'BROCK',
    symbol: 'BROCK', // Replace with the actual symbol
    decimals: 18,
  },
  rpcUrls: {
    default: 'https://rpc.customnetwork.com', // Replace with the actual RPC URL
  },
  blockExplorers: {
    default: { name: 'Custom Explorer', url: 'https://explorer.customnetwork.com' }, // Replace with the actual explorer details
  },
  testnet: false,
};



const { chains, provider } = configureChains(
  [customNetwork],
  [alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

function WalletInfo() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [success, setSuccess] = useState()
  const [isMintClicked, setIsMintClicked] = useState(false);

  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 3) {
      setMintAmount(mintAmount + 1);
    }
  };

  const contractAddress = '0x32FF05d17Ae4095bFFBa3Eb4594BECf3C1dfd46C';

  const mintNFT = async () => {
   

   
      try {
        const contractAddress = '0x32FF05d17Ae4095bFFBa3Eb4594BECf3C1dfd46C'; 
        if (!account || !account.connector) {
          alert('Please connect your wallet first.');
          return;
        }

        const signer = await account.connector.getSigner();
        const contract = new ethers.Contract(contractAddress, ABI, signer);

        const transactionOverrides = {
          gasLimit: ethers.utils.hexlify(150000),
        };
    
        const priceInWei = ethers.utils.parseEther("0");
        const totalValue = priceInWei.mul(ethers.BigNumber.from(mintAmount));

        // Hash the coupon code
       
        // Add the coupon hash to the mint function call
        let transaction = await contract.mint(mintAmount, {
          ...transactionOverrides,
          value: totalValue,
        });  

        await transaction.wait();
        setSuccess(true);

      } catch (error) {
        console.error('An error occurred!', error);
      }
   
  };


  useEffect(() => {
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);

  return (
    <header className="">
   
  </header>
  );
}

function Mint() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [success, setSuccess] = useState()
  const [isMintClicked, setIsMintClicked] = useState(false);
  const [supply, setSupply] = useState()
  const [couponCode, setCouponCode] = useState('');
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);

  const USDB_CONTRACT_ADDRESS = "0xD647a6E1F898c39bA594443c19De0872EA935310"; 


  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 3) {
      setMintAmount(mintAmount + 1);
    }
  };

  

  const contractAddress = '0x32FF05d17Ae4095bFFBa3Eb4594BECf3C1dfd46C';

  const twitterBaseUrl = "https://twitter.com/intent/tweet?text=";
  const tweetText = encodeURIComponent("🦍Just minted this incredible #Mutated #Primate on the #Bitrock chain! 1000 unique pieces available, hand-drawn (no AI involved)! Stunning art and $BROCK gimmicks in the collection. 😍 Posting my NFT makes me eligible to win 1000 $BROCK🌐www.Mutated-Primates.com 🚀#Web3 #NFT");
  const twitterShareUrl = `${twitterBaseUrl}${tweetText}`;



  useEffect(() => {
    const fetchSupply = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, ABI, provider);
      try {
        const supply = await contract.supply();
        // If your totalSupply() returns a BigNumber, you should convert it to a string
        setSupply(supply.toString());
      } catch (error) {
        console.error("Error fetching supply:", error);
      }
    };
  
    fetchSupply();
  }, []);

  const mintNFT = async () => {
    try {
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
  
      const signer = await account.connector.getSigner();
  
      // USDC contract interaction
      const usdcContract = new ethers.Contract(USDB_CONTRACT_ADDRESS, USDCABI, signer);
      const nftPrice = ethers.utils.parseUnits("100", 6); // Replace AMOUNT_IN_USDC with your price per NFT in USDC
      const totalCost = nftPrice.mul(ethers.BigNumber.from(mintAmount));
      await usdcContract.approve(contractAddress, totalCost);
  
      // NFT contract interaction
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      let transaction = await contract.mint(mintAmount); 
      await transaction.wait();
      setSuccess(true);
  
    } catch (error) {
      console.error('An error occurred!', error);
    }
  };
  


  useEffect(() => {
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);

  return (
    <div className='app'>
      < div>
          
      </div>
      <img src={logoMutated} className="mutated"></img>
      {isWalletConnected ? 
      success ? 
      <div>
      <h2 style={{color:'white'}}>Minted Successfully</h2>
      <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
          <button className='share'>Share it on Twitter</button>
      </a>
  </div> :
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
          
          <h2 className='subtitle2'>{mintAmount}</h2>
          <div><button className="decrement-button" onClick={handleDecrement}>-</button>
         
      <button className="mint-button"  onClick={mintNFT} disabled={isVerifyingCode}>
        {isVerifyingCode ? "Verifying..." : "Mint"}
      </button>
          <button className="increment-button" onClick={handleIncrement}>+</button> </div>
        </div>
      :<button 
        className="mint-button" 
        onClick={mintNFT} 
        disabled={!isWalletConnected || isMintClicked}
      >
        Mint
      </button>}</div>
);
}

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={darkTheme({
      accentColor: '#44d51c',
      accentColorForeground: 'black',
      borderRadius: 'small',
      fontStack: 'system',
      overlayBlur: 'small',
    })}  chains={chains}>
      <div class="app">
      <header className="app-header">
        <img />
        <div className='header-menu'> 
        
        <ConnectButton className="header-button" style={{ backgroundColor: 'yellow!' }} />
        
        </div>
      </header>
      
      <WalletInfo />
          <main className="main-content">
          
            <Mint/>
          </main>
         
        </div>
       
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
